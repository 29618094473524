import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { FaqDetail } from "../../../components/faq/detail"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `FAQ`, path: `question` },
  { text: `ネクストライブについて`, path: `question/company` },
]

const faqNavItems: { text: string; path: string }[] = [
  { text: `ネクストライブについて`, path: `#faq_nextribe` },
]

const FaqTitle: string = "ネクストライブに関するよくある質問";

export default function FaqCompanyPage() {
  const { site: { siteMetadata: { faqDetail } } } = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        faqDetail {
          company {
            details {
              answer
              question
            }
            path
            text
          }
        }
      }
    }
  }
`);

  const [ faqDetails ] = faqDetail;

  return (
    <FaqDetail
      breadcrumbsData={breadcrumbsData}
      faqNavItems={faqNavItems}
      faqDetails={faqDetails.company}
      faqTitle={FaqTitle}
    />
  )
}