import React from "react"
import styled from "styled-components"
import { Section } from "../../common/Section"
import { RESPONSIVE_STYLES } from "../../../../AppConst"

const Wrapper = styled.div`
  text-align: center;
  & > p {
    margin-bottom: 160px;
    font: 20px/40px Hiragino Sans;
    font-weight: 600;
    letter-spacing: 2px;
    color: #222222;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font: 14px/25px Hiragino Sans;
      font-weight: 600;
      letter-spacing: 1.4px;
      margin-top: 48px;
      margin-bottom: 80px;
      text-align: center;
    }
  }
  & dl dd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  & .sp {
    display: none;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      display: block;
    }
  }
`

type Props = {
  faqDetails: any,
}

export const Main: React.FC<Props> = ({ faqDetails }) => {
  return (
    <Wrapper>
      {faqDetails.map((item) => {
        return(
          <Section id={item.path}>
            {item.text && <h3>{item.text}</h3>}
            {item.details.map((detail) => {
              return(
              <dl>
                <dt>{detail.question}</dt>
                <dd>{detail.answer.map((answer) => {
                  return ( <div dangerouslySetInnerHTML={{__html: answer}} /> )
                })}</dd>
              </dl>
              )
            })}
          </Section>
        )
      })}
      <p>当ページにご質問が見当たらない場合や、<br className="sp" />その他のご質問がある場合は、<br />以下のフォームよりお問い合わせください。</p>
    </Wrapper>
  )
}