import React from "react"
import Layout from "../../../template/Layout"
import styled from "styled-components"
import Head from "../../../head";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../../AppConst"
import { SpWrapper } from "../../common/SpWrapper"
import { SectionTitle } from "../../common/SectionTitle";
import Breadcrumbs, {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { TopNav } from "../../common/TopNav"
import { Main } from "./main"

/**
 * Style
 */
const Container = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  box-sizing: border-box;
  & .sectionTitle {
    margin-top: 18px;
    margin-bottom: 80px;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-top: 21px;
  }
`
const Title = styled.h2`
  font-family: 'Hiragino Sans';
  font-size: 3.2rem;
  line-height: 0.75;
  letter-spacing: 3.2px;
  margin-top: 40px;
  padding-bottom: 96px;
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 2.0rem;
    line-height: normal;
    letter-spacing: 2px;
    padding-bottom: 16px;
  }
`

/**
 * Component
 */

type Props = {
  breadcrumbsData: BreadcrumbsData["values"],
  faqNavItems?: { text: string; path: string }[],
  faqDetails: any,
  faqTitle: string,
}

export const FaqDetail: React.FC<Props> = ({
  breadcrumbsData,
  faqNavItems,
  faqDetails,
  faqTitle,
}) => {
  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title={`${faqTitle} | ネクストライブ株式会社`}
        description={`ネクストライブ株式会社の${faqTitle}を掲載しています。`}
      />
      <Container>
        <SpWrapper>
          <div className="sectionTitle">
            <SectionTitle title="FAQ" subTitle="よくある質問" />
          </div>
          <Title>{faqTitle}</Title>
          <Main faqDetails={faqDetails}/>
        </SpWrapper>
      </Container>
    </Layout>
  )
}
