import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"

export const Section = styled.section`
  padding-bottom: 84px;
  & > h3 {
    text-align: left;
    font-family: 'Hiragino Sans';
    font-size: 24px;
    line-height: 1;
    letter-spacing: 2.4px;
    max-width: 960px;
    margin: 0px auto 40px;
    padding-bottom: 16px;
    border-bottom: 1px solid #222222;
  }
  & dl {
    width: 100%;
    max-width: 960px;
    text-align: left;
    margin: 0px auto 48px;
  }
  & dt, dd {
    position: relative;
    min-height: 50px;
    margin-left: 65px;
    font-size: 1.4rem;
    letter-spacing: 1.1px;
    box-sizing: border-box;
    /* テキストを縦方向に中央揃え */
    display: flex;
    align-items: center;
  }
  & dt {
    font: 20px/32px Hiragino Sans;
    font-weight: 600;
    letter-spacing: 2px;
  }
  & dd {
    font: 16px/32px Hiragino Sans;
    font-weight: 400;
    margin-top: 12px;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    padding: 24px;

    & .targetLabel {
      display: block;
      width: fit-content;
      margin-bottom: 5px;
      padding: 2px 8px;
      border: 1px solid #222222;
      line-height: normal;
    }

    & .targetLabel__mt10 {
      margin-top: 10px;
    }

    & a {
      color: #61B1C1;
      text-decoration-line: underline;
    }
  }
  & dt:before {
    position: absolute;
    text-align: center;
    width: 50px;
    height: 50px;
    left: -65px;
    border-radius: 50%;
    margin-right: 15px;
    font-family: 'Righteous';
    font-size: 2.4rem;
    font-weight: 500;
    /* テキストを縦方向に中央揃え */
    display: flex;
    justify-content: center;
    align-items: center;
    content: "Q";
    color: #000;
    background-color: #61B1C1;
  }
  & .sp {
    display: none !important;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      display: block !important;
    }
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-bottom: 8px;
    & h3 {
      font: 16px Hiragino Sans;
      font-weight: 600;
      letter-spacing: 1.6px;
      margin: 48px auto 24px;
      padding-bottom: 8px;
      color: #222222;
    }
    & dl {
      width: 100%;
      margin: 0px auto 24px;
    }
    & dt, dd {
      display: block;
      min-height: 20px;
      margin-left: 40px;
      font: 14px/26px Hiragino Sans;
      letter-spacing: 0.95px;
      line-height: 1.67;
      border-width: 2px;
    }
    & dt {
      font-weight: 600;
    }
    & dd {
      font-weight: 400;
      margin-top: 8px;
      padding: 16px;
    }
    & dt:before {
      width: 30px;
      height: 30px;
      top: -6px;
      left: -40px;
      margin-right: 10px;
      font: 16px Righteous;
    }
  }
`
